import PropTypes from "prop-types";
import NavigationComponent from "components/Navigation";
import useUser from "hooks/useUser";
import Logo from "components/Logo"
import { exportUsers } from "routes/statistics/services";
import { toast } from "react-toastify";
import dPackage from '../../../package.json'
import {
  MenuSideBar,
  Content,
  Container,
  Wrapper,
  GreetingUser,
  SideBarFooter
} from "./Styles";
import NavigationItems from "generated/menuItems.json";

const Layout = ({
  hide = false,
  children,
  hideLeft = false,
  hideRight = false,
}) => {
  const { user } = useUser((state) => state.user);
  const { access } = useUser((state) => state.user);

  const handleExport = async () => {
    try {
      await exportUsers(access.token);
      toast.success("Export successful!");
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Export failed!");
    }
  };

  return (
    <>
      <Wrapper>
        {
          !hide && (
            <Container>
              {!hideLeft ? (
                <MenuSideBar>
                 <Logo />
                  <GreetingUser> {user.firstname}  </GreetingUser>
               
                  <NavigationComponent items={NavigationItems} />
                 
               
                  <SideBarFooter>Marrakech v-{dPackage.version}</SideBarFooter>
                  <br/>
                  <button onClick={handleExport}>
                    Exportation (CSV)
                  </button>

                </MenuSideBar>
              ) : null}
              <Content fullscreen={hideLeft || hideRight }>
                {children}
              </Content>
         
            </Container>
          )
        }
       
      </Wrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  hideLeft: PropTypes.bool,
  hideRight: PropTypes.bool,
  RightContent: PropTypes.element,
  LeftContent: PropTypes.element,
};

export default Layout;
