import {  fetchit } from 'lib'
import { toast } from "react-toastify";

import {
    API_STATS,
    API_EXPORT,
} from 'config/api'


export function getStats(accessToken) {
    const url = `${API_STATS}`
    return fetchit(url, null, 'GET', accessToken)
}



export async function exportUsers(accessToken) {
    try {
      const response = await fetch(API_EXPORT, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        toast.error(errorText || response.statusText);
        throw new Error(errorText || response.statusText);
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'exported_data.csv'; // Set appropriate filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
  
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Export failed!");
    }
  }